* {
  font-family: "Poppins", "Inter", sans-serif;
}

.title {
  font-family: "Poppins", "Inter", sans-serif;
  font-size: 1.5rem;
  font-weight: bolder;
  margin: 0px;
}

.subtitle {
  font-family: "Poppins", "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: bolder;
  margin: 0px;
}

.description {
  font-family: "Poppins", "Inter", sans-serif;
  font-size: 1rem;
  font-weight: bolder;
  margin: 0px;
}
