.create-account-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.create-account-container .logo-image {
  padding: 20px;
  width: 125px;
  height: 30px;
  object-fit: contain;
}
.create-account-form .sign-in-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 5px;
  font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.create-account-form .MuiInputBase-root {
  width: 320px;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .create-account-form .MuiInputBase-root {
    width: 300px; /* Adjust the width for smaller screens */
  }
}

/* Media Query for even smaller screens */
@media (max-width: 480px) {
  .create-account-form .MuiInputBase-root {
    width: 250px; /* Adjust the width for even smaller screens */
  }
}

.create-account-form .sign-in-container a {
  color: #007bff;
  text-transform: uppercase;
  font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.create-account-form.create-account-form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
}

.create-account-form.create-account-form-container .form-wrapper {
  display: flex;
  padding: 10px;
  flex-direction: column;
  max-width: 350px;
  align-self: center;
  background-color: #fff;
}

.is_shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.create-account-form .form-title {
  margin-top: 10px;
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
  font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.create-account-form.create-account-form-container
  .form-wrapper
  .form-container {
  display: flex;
  flex-direction: column;
}

.create-account-form .form-wrapper .form-container .form-row {
  display: flex;
  flex-direction: row;
  margin: 5px 15px;
}

.create-account-form .form-element-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 5px 15px;
}

.create-account-form .form-element-wrapper .bootstrap-input-label {
  font-size: 1rem;
  font-weight: bold;
  font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  color: #242424;
}

.create-account-form .company-details-wrapper .form-element-wrapper {
  margin: 15px 5px;
}

.create-account-form .accordion-header {
  font-size: 1rem;
  font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  color: #212121;
}
/* radio button */
.create-account-form .radio-inputs {
  display: flex;
  justify-content: space-between;
  /* max-width: 350px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 5px 15px;
  margin-bottom: 20px;
}

.create-account-form .radio-inputs > * {
  margin: 6px;
}

.create-account-form .radio-inputs label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.create-account-form .radio-input:checked + .radio-tile {
  border-color: rgba(0, 123, 255, 0.5);
  color: #bdbdbd;
}

.create-account-form .radio-input:checked + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #007bff;
  border-color: #eeeeee;
  outline-offset: 0px;
  outline-color: #007bff;
  outline-width: 2px;
  outline-style: solid;
}

.create-account-form .radio-input .radio-tile .radio-icon svg {
  fill: #bdbdbd;
}

.create-account-form .radio-input .radio-tile:hover .radio-icon svg {
  fill: #007bff;
}

.create-account-form .radio-input:checked + .radio-tile .radio-label {
  color: #007bff;
}

.create-account-form .radio-input:checked + .radio-tile .radio-icon svg {
  fill: #007bff;
}

.create-account-form .radio-input:focus + .radio-tile {
  border-color: #007bff;
  box-shadow: 0 5px 10px rgba(0, 123, 255, 0.1);
}

.create-account-form .radio-input:focus + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
}

.create-account-form .radio-tile {
  display: flex;
  flex-direction: row;
  padding: 5px 20px;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 2px solid rgba(189, 189, 189, 0.05);
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(189, 189, 189, 0.1);
  transition: all 0.15s ease;
  cursor: pointer;
  position: relative;
}

.create-account-form .radio-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border: 2px solid #007bff;
  background-color: #fff;
  border-radius: 50%;
  top: 10px;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
  box-shadow: 0 5px 10px rgba(0, 123, 255, 0.1);
}

.create-account-form .radio-tile:hover {
  border-color: rgba(0, 123, 255, 0.25);
}

.create-account-form .radio-tile:hover .radio-label,
.create-account-form .radio-tile:hover .radio-icon svg {
  color: #007bff !important;
  fill: #007bff !important;
}

.create-account-form .radio-tile .radio-label,
.create-account-form .radio-tile .radio-icon svg {
  color: #bdbdbd;
}

.create-account-form .radio-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.create-account-form .radio-icon svg {
  margin-left: 5px;
  width: 1rem;
  height: 1rem;
  fill: #bdbdbd;
}
.create-account-form .radio-input:checked .radio-icon svg {
  color: #007bff;
}

.create-account-form .radio-label {
  margin-left: 5px;
  color: #242424;
  transition: 0.375s ease;
  text-align: center;
  font-size: 13px;
}

.create-account-form .radio-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.create-account-form .create-account-button {
  width: 300px;
  color: #fff;
  border: 0px;
  cursor: pointer;
  background-color: rgb(0, 123, 255);
  padding: 15px 25px;
  border-radius: 2px;
  margin: 20px 25px;
  font-size: 16px;
  border-radius: 5px;
  font-family: "Poppins", "Inter", sans-serif;
  transition: all 0.4s ease-in-out;
}

.create-account-form .create-account-button.disabled,
.create-account-form .create-account-button.disabled:hover,
.create-account-form .create-account-button.disabled:focus {
  border-radius: 2px;
  background-color: #bdbdbd !important;
  box-shadow: none !important;
}

.create-account-form .create-account-button:hover {
  border-radius: 10px;
  box-shadow: rgba(0, 123, 255, 0.2) 0px 7px 29px 0px;
}

.create-account-form .phone-number-wrapper {
  display: flex;
  flex-direction: row;
}

.create-account-form .phone-number-wrapper .form-element-wrapper {
  display: flex;
  margin-left: 12px;
}

.create-account-form .phone-number-wrapper .phone-code {
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", "Inter", sans-serif;
}

.create-account-form .terms-text-wrapper {
  font-size: 12px;
  font-family: "Poppins", "Inter", sans-serif;
}
/* Define the blinking cursor animation */
@keyframes cursor {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.create-account-form .form-row.country-select-wrapper {
  align-items: center;
}

.create-account-form .social-auth-wrapper {
  display: flex;
  flex-direction: column;
}

.create-account-form .social-auth-wrapper .social-auth-title {
  color: #242424;
  font-family: "Poppins", "Inter", sans-serif !important;
  font-size: small;
  text-align: center;
}

.create-account-form .social-auth-wrapper .form-row {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.create-account-form .social-auth-wrapper .form-row button {
  margin: 5px 10px;
}

.create-account-form .divider-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.create-account-form .divider-wrapper p {
  font-size: 12px;
  color: #bdbdbd;
  padding: 5px 10px;
  font-family: "Poppins", "Inter", sans-serif !important;
}

.create-account-form .error-text {
  color: #ff1744 !important;
  font-size: 14px;
  font-family: "Poppins", "Inter", sans-serif !important;
}
