.setting{
    font-size: 16px;
    color: #000000;
    
    margin-left:53%;                    
    font-weight: 400!important;
    text-transform: capitalize;
    display: inline-flex;
 
    
}
.setting:hover{
    color:#4fc3f7 ;
}
.setting:focus{
    color:#4fc3f7 ;
}
.setting2:focus{
    color:#007bff;
    
}
.setting-icon{
    margin-left:12%;  
}
.menu-item{
    margin-left:3%;
} 
.hov:hover{
    color:#4fc3f7 ;
    
}
.settings-icondiv{
    font-size:16px;
    font-weight:thin;
    display:flex;
    color:black;
  
    margin-left:34px;
    margin-right:60px;
    padding-top: 12px;
}

.publish-and-settings{
  display: flex;
  /* align-self: flex-end; */
  gap: 120px;
  height: 10%;
  flex-direction: column;
  justify-content: space-between;
}



.NavLink-div{
  display: flex;
  font-family: 'Poppins',sans-serif;
}


.NavLink-icon{
  display: flex;
  justify-content: center;
  margin-left: 2.5px;
  margin-right: 16px;
  align-items: center;
}

.chevron-right-icon{
  margin-left: 72px;
  margin-top: -2px;
}


.settings-icondiv:hover{
    color:#4fc3f7
}

.set{
    margin-left:17px;
    font-size:16px;
    font-weight:300;
}
  
  .copy-area {
    display: flex;
    align-items: center;
  }

  .copy-feedback {
    margin-left: 1rem;
    visibility: hidden;
  }
  
  .copy-feedback.active {
    visibility: visible;
  }
  
  @media screen and (max-width: 600px) {
    .container,
    .copy-area {
      flex-direction: column;
    }
  
    .copy-feedback {
      display: block;
      margin-top: 0.5rem;
    }
  }