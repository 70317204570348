.pulse {
    animation: pulse-animation 2s infinite;
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(255, 23, 68, 0.35);
     
    }
    100% {
      box-shadow: 0 0 0 20px rgba(255, 23, 68, 0.01);
     
    }
  }
  