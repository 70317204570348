html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  margin: 0;
}

#root {
  width: 100vw !important;
  min-height: 100vh;
}

.App {
  display: flex;
  flex: 1;
  height: 100%;
  min-height: 100vh;
}
