.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%; 
    /* height: 85vh;  */
    /* background-color: yellow; */
  }
  .form-title {
    font-size: 1.8rem;
    margin-top: -15px;
    text-align: center;
    text-transform: capitalize;
    font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }
  .form-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: 'center';
    margin-left: 20%;
    /* margin-top: -10px; */
  }
  .form-element-wrapper .bootstrap-input-label {
    margin-bottom: 30px;
    font-size: 1rem;
    font-weight: bold;
    font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    color: #242424;
    
  }
  .company-selector-wrapper {
    margin-top: 23px;
    max-width: 230px;
  }
  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .create-account-button {
    color: #fff;
    border: 0px;
    background-color: #212121;
    padding: 15px 25px;
    border-radius: 2px;
    margin: 20px 0px;
    font-size: 16px;
    font-family: "Poppins", "Inter", sans-serif;
    transition: all 0.2s ease-in-out;
  }
  
  .check-form {
    font-size: 1rem;
    font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    color: #242424;
  }
  
  .phone-number-wrapper{
    margin-top: 20px;
  }

  .login-account-button {
    width: 250px;
    color: #fff;
    border: 0px;
    background-color: #212121;
    padding: 15px 25px;
    border-radius: 2px;
    margin: 10px 0px;
    font-size: 16px;
    border-radius: 5px;
    font-family: "Poppins", "Inter", sans-serif;
    transition: all 0.2s ease-in-out;
  }
  .logout-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }