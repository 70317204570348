.session-expire-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.session-expire-container {
  background-color: #fff;
  border-radius: 15px;
  padding: 50px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.session-expire-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.session-expire-description {
  font-size: 16px;
  margin-bottom: 20px;
  font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
  sans-serif;
}
