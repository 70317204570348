.chat-fetching {
  display: flex;
  width: 100%;
  background-color: #e1f5fe;
  color: #007bff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-family: "Poppins", "Inter", sans-serif;
}

.loader {
  --ballcolor: #007bff;
  --shadow: 0px 0 #ffffff00;
  --shadowcolor: #ffffff00;
  width: 5px;
  height: 5px;
  left: 0px;
  border-radius: 50%;
  position: relative;
  color: var(--ballcolor);
  animation: loadingDotFlying 2s linear infinite;
}

@keyframes loadingDotFlying {
  0% {
    box-shadow: var(--shadow), var(--shadow), var(--shadow), var(--shadow);
  }

  12% {
    box-shadow: 10px 0 var(--ballcolor), var(--shadow), var(--shadow),
      var(--shadow);
  }

  25% {
    box-shadow: 15px 0 var(--ballcolor), 10px 0 var(--ballcolor), var(--shadow),
      var(--shadow);
  }

  36% {
    box-shadow: 20px 0 var(--ballcolor), 15px 0 var(--ballcolor),
      10px 0 var(--ballcolor), var(--shadow);
  }

  50% {
    box-shadow: 30px 0 var(--ballcolor), 20px 0 var(--ballcolor),
      15px 0 var(--ballcolor), 10px 0 var(--ballcolor);
  }

  62% {
    box-shadow: 100px 0 var(--shadowcolor), 30px 0 var(--ballcolor),
      20px 0 var(--ballcolor), 15px 0 var(--ballcolor);
  }

  75% {
    box-shadow: 100px 0 var(--shadowcolor), 100px 0 var(--shadowcolor),
      30px 0 var(--ballcolor), 20px 0 var(--ballcolor);
  }

  87% {
    box-shadow: 100px 0 var(--shadowcolor), 100px 0 var(--shadowcolor),
      100px 0 var(--shadowcolor), 30px 0 var(--ballcolor);
  }

  100% {
    box-shadow: 100px 0 var(--shadowcolor), 100px 0 var(--shadowcolor),
      100px 0 var(--shadowcolor), 100px 0 var(--shadowcolor);
  }
}

.message-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.message-wrapper.incoming {
  align-items: flex-start;
}

.message-wrapper.outgoing {
  align-items: flex-end;
}

.message-wrapper .time {
  font-size: 0.75rem;
  font-family: "Poppins", "Inter", sans-serif;
}

.message-wrapper.incoming .time {
  align-items: flex-start;
  color: #bdbdbd;
}

.message-wrapper.outgoing .time {
  align-items: flex-end;
  color: #bdbdbd;
}

.chat-timeline-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.chat-timeline-indicator .timeline-text {
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 0.75rem;
  font-family: "Poppins", "Inter", sans-serif;
}

.chat-message-bubble {
  display: flex;
  padding: 5px 10px;
  margin-bottom: 2px;
  max-width: 60%;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  font-family: "Poppins", "Inter", sans-serif;
}

.chat-message-bubble.incoming {
  align-items: flex-start;
  background-color: #eeeeee;
  color: #242424;
  border-bottom-left-radius: 2px;
}

.chat-message-bubble.incoming .message-text {
  color: #242424 !important;
}

.chat-message-bubble.incoming.follow-up {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.chat-message-bubble.incoming.follow-up.end {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 10px;
}

.chat-message-bubble.outgoing {
  align-items: flex-end;
  background-color: #007bff;
  border-bottom-right-radius: 2px;
}

.chat-message-bubble.outgoing.follow-up {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.chat-message-bubble.outgoing.follow-up.end {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 10px;
}

.chat-message-bubble.outgoing .message-text {
  color: #fff !important;
}

.chat-message-bubble .message-text {
  font-size: 0.85rem;
  font-family: "Poppins", "Inter", sans-serif;
  word-break: break-word;
}

.empty-list-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.empty-list-section .error-text {
  color: #bdbdbd;
  font-size: 1rem;
  font-family: "Poppins", "Inter", sans-serif;
}

.empty-list-section .no-message-image {
  width: 150px;
  height: 150px;
  object-fit: contain;
}
