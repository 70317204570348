.chat-window-wrapper {
  transition: all 0.5s ease-in-out;
}

.chat-window {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 325px;
  border-radius: 15px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  z-index: 9999;
  transition: all 0.8s ease;
}

.chat-window.minimized {
  display: none;
}

.chat-window.closed {
  bottom: -200vw;
}

.chat-popper-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  padding: 2px;
  background-color: #fff;
  border: 2px solid #007bff;
  cursor: pointer;
  z-index: 1000;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.chat-popper-container:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px -4px 12px;
}

.chat-popper-container.minimized {
  bottom: 20px;
}
.chat-popper-container.closed {
  bottom: -200vw;
}

.chat-window .chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.chat-window .chat-header-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.chat-header .profile-picture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.chat-window .chat-body {
  height: 320px;
  max-height: 320px;
  display: flex;
  flex-direction: column;
  padding: 0px 5px;
}

.chat-window .chat-footer {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.chat-window .message {
  display: inline-block;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 8px;

  font-size: 14px;
}

.chat-window .message-input {
  flex: 1;
  margin-right: 2px;
  font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.chat-fetching {
  display: flex;
  width: 100%;
  background-color: #e1f5fe;
  color: #007bff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-family: "Poppins", "Inter", sans-serif;
}

.loader {
  --ballcolor: #007bff;
  --shadow: 0px 0 #ffffff00;
  --shadowcolor: #ffffff00;
  width: 5px;
  height: 5px;
  left: 0px;
  border-radius: 50%;
  position: relative;
  color: var(--ballcolor);
  animation: loadingDotFlying 2s linear infinite;
}

@keyframes loadingDotFlying {
  0% {
    box-shadow: var(--shadow), var(--shadow), var(--shadow), var(--shadow);
  }

  12% {
    box-shadow: 10px 0 var(--ballcolor), var(--shadow), var(--shadow),
      var(--shadow);
  }

  25% {
    box-shadow: 15px 0 var(--ballcolor), 10px 0 var(--ballcolor), var(--shadow),
      var(--shadow);
  }

  36% {
    box-shadow: 20px 0 var(--ballcolor), 15px 0 var(--ballcolor),
      10px 0 var(--ballcolor), var(--shadow);
  }

  50% {
    box-shadow: 30px 0 var(--ballcolor), 20px 0 var(--ballcolor),
      15px 0 var(--ballcolor), 10px 0 var(--ballcolor);
  }

  62% {
    box-shadow: 100px 0 var(--shadowcolor), 30px 0 var(--ballcolor),
      20px 0 var(--ballcolor), 15px 0 var(--ballcolor);
  }

  75% {
    box-shadow: 100px 0 var(--shadowcolor), 100px 0 var(--shadowcolor),
      30px 0 var(--ballcolor), 20px 0 var(--ballcolor);
  }

  87% {
    box-shadow: 100px 0 var(--shadowcolor), 100px 0 var(--shadowcolor),
      100px 0 var(--shadowcolor), 30px 0 var(--ballcolor);
  }

  100% {
    box-shadow: 100px 0 var(--shadowcolor), 100px 0 var(--shadowcolor),
      100px 0 var(--shadowcolor), 100px 0 var(--shadowcolor);
  }
}
