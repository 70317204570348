.login-account-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.login-account-container .logo-image {
  padding: 20px;
  width: 125px;
  height: 30px;
  object-fit: contain;
}

.login-account-form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sign-up-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
  margin-top: 20px;
  font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.sign-up-container a {
  color: #007bff;
  text-transform: uppercase;
  font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  border-radius: 10px;
}

.login-form .MuiInputBase-root {
  width: 320px;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .login-form .MuiInputBase-root {
    width: 300px; /* Adjust the width for smaller screens */
  }
}

/* Media Query for even smaller screens */
@media (max-width: 480px) {
  .login-form .MuiInputBase-root {
    width: 250px; /* Adjust the width for even smaller screens */
  }
}

.login-account-form-container .form-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.form-title {
  margin-top: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
  font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.login-account-form-container .form-wrapper .form-container {
  display: flex;
  flex-direction: column;
}

.form-wrapper .form-container .form-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 10px 15px;
}

.form-element-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 5px 15px;
}

.form-element-wrapper .bootstrap-input-label {
  font-size: 1rem;
  font-weight: bold;
  font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  color: #242424;
}

.form-row > .login-account-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.login-account-button {
  width: 300px;
  color: #fff;
  border: 0px;
  cursor: pointer;
  background-color: rgb(0, 123, 255) !important;
  padding: 15px 25px;
  border-radius: 2px;
  margin: 20px 25px;
  font-size: 16px;
  border-radius: 5px;
  font-family: "Poppins", "Inter", sans-serif;
  transition: all 0.4s ease-in-out;
}

.login-account-button:hover {
  border-radius: 10px;
  box-shadow: rgba(0, 123, 255, 0.2) 0px 7px 29px 0px !important;
}

.forget-password-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 10px;
}
.forget-password-wrapper a {
  color: #242422 !important;
  font-family: "Poppins", "Inter", sans-serif !important;
  font-size: small;
  transition: all 0.2s ease-in-out;
}
.forget-password-wrapper a:hover {
  color: #007bff !important;
  font-size: bold;
}

.social-auth-wrapper {
  display: flex;
  flex-direction: column;
}

.social-auth-wrapper .social-auth-title {
  color: #242424;
  font-family: "Poppins", "Inter", sans-serif !important;
  font-size: small;
  text-align: center;
}

.social-auth-wrapper .form-row {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.social-auth-wrapper .form-row button {
  margin: 5px 10px;
}

.divider-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.divider-wrapper p {
  font-size: 12px;
  color: #bdbdbd;
  padding: 5px 10px;
  font-family: "Poppins", "Inter", sans-serif !important;
}

/* Define the blinking cursor animation */
@keyframes cursor {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Apply the typing animation to the text */
.typing-style {
  display: inline-block;
  overflow: hidden;
  animation: typing 2s steps(30, end), cursor 0.5s step-end infinite;
  white-space: nowrap;
  border-right: 1px solid;
}

/* Define the typing animation */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.form-row.country-select-wrapper {
  align-items: center;
}

fieldset.company-details-wrapper {
  border: 1px solid rgba(36, 36, 36, 0.192);
  border-radius: 15px;
  margin-right: 5px;
}

fieldset.company-details-wrapper legend {
  width: auto;
  margin-top: -10px;
  margin-left: 10px;
  padding: 0px 10px;
  font-size: 12px;
  color: #242424;
  background-color: #fff;
  font-family: "Poppins", "Inter", sans-serif;
}

.forget-password-container-wrapper {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.forget-password-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.reset-password-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.sign-in-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 10px;
  font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.sign-in-container a {
  color: #007bff;
  font-family: "Poppins", "Inter", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.login-form .error-text {
  color: #ff1744 !important;
  font-size: 14px;
  font-family: "Poppins", "Inter", sans-serif !important;
}

@media (max-width: 768px) {
  .login-account-container {
    flex-direction: column;
  }

  .login-account-form-container,
  .sign-up-container {
    width: 100%;
  }

  .form-row {
    flex-direction: column;
  }

  .login-account-button {
    width: 100%;
  }

  .form-element-wrapper {
    margin: 5px 0;
  }

  .form-wrapper .form-container .form-row {
    margin: 10px 0;
  }

  .login-account-button {
    margin: 20px 0;
  }

  fieldset.company-details-wrapper {
    margin-right: 0;
  }
}
