/* blob */
.shape-blob {
    background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
    height: 200px;
    width: 200px;
    border-radius: 30% 50% 20% 40%;
    animation: transform 20s ease-in-out infinite both alternate,
      movement_one 40s ease-in-out infinite both;
    opacity: 1;
    position: absolute;
    left: 45%;
    top: 40%;
  }
  
  .shape-blob.two {
    background-image: linear-gradient(to right, #43e97b 0%, #38f9d7 100%);
    height: 350px;
    width: 350px;
    left: 25%;
    top: -150px;
    transform: rotate(-180deg);
    animation: transform 30s ease-in-out infinite both alternate,
      movement_two 60s ease-in-out infinite both;
  }
  
  @keyframes transform {
    0%,
    100% {
      border-radius: 33% 67% 70% 30% / 30% 30% 70% 70%;
    }
    20% {
      border-radius: 37% 63% 51% 49% / 37% 65% 35% 63%;
    }
    40% {
      border-radius: 36% 64% 64% 36% / 64% 48% 52% 36%;
    }
    60% {
      border-radius: 37% 63% 51% 49% / 30% 30% 70% 70%;
    }
    80% {
      border-radius: 40% 60% 42% 58% / 41% 51% 49% 59%;
    }
  }
  
  @keyframes movement_one {
    0%,
    100% {
      transform: none;
    }
    50% {
      transform: translate(50%, 20%) rotateY(10deg) scale(1.2);
    }
  }
  
  @keyframes movement_two {
    0%,
    500% {
      transform: none;
    }
    50% {
      transform: translate(60%, 20%) rotate(-200deg) scale(1.2);
    }
  }